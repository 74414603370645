import { Box, Button, Container, SxProps, Theme, Typography, useTheme } from '@mui/material';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
interface ErrorScreenProps {
  message?: string | null;
  icon?: any;
  onRetry?: () => void;
}

const styles: Record<string, SxProps<Theme>> = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100%',
    textAlign: 'center',
    padding: 3,
  },
  image: {
    marginBottom: 4,
    borderRadius: 2,
    overflow: 'hidden',
  },
  message: {
    marginBottom: 4,
  },
};

export default function ErrorScreen({ message, icon, onRetry }: ErrorScreenProps) {
  const theme = useTheme();
  return (
    <Container maxWidth="sm">
      <Box sx={styles.root}>
        {icon ? 
          <Box sx={styles.image}>
           {icon}
          </Box> :
          <ErrorOutlineIcon sx={{ fontSize: 80, color: theme.palette.error.main }} />
        }
        <Typography variant="h4" component="h1" gutterBottom>
          Oops! Something went wrong.
        </Typography>
        <Typography variant="body1" sx={styles.message}>
          {message || 'We encountered an unexpected error. Please refresh the page or try again later.'}
        </Typography>
        {onRetry && (
          <Button variant="contained" color="primary" onClick={onRetry}>
            Try Again
          </Button>
        )}
      </Box>
    </Container>
  );
}

