import axios from 'axios';
import localStorageStore from '../store/localStorageStore';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export const backendApi = import.meta.env.VITE_APP_ENDPOINT;

let activeRequests = 0; // Global counter for tracking active requests
let progressBarStarted = false; // Flag to ensure progress bar starts only once

export default function axiosInstance(history: any = null, passedHeaders: any = null) {
  let headers: any = passedHeaders ? passedHeaders : {};

  if (localStorage.token) {
    headers.Authorization = `Bearer ${localStorage.token}`;
  }

  const entityId = localStorage.getItem('selectedEntity');
  if (entityId) {
    headers.entity = entityId;
  }

  const axiosInstance = axios.create({
    baseURL: backendApi,
    headers,
  });

  function clearTokenAndRedirectToHome() {
    localStorageStore.logout();
    if (history) {
      history.push('/');
    } else {
      // history.push('/');
      //@ts-ignore
      window.location = '/';
    }
  }

  // Start progress bar only once
  function startProgressBar() {
    if (!progressBarStarted) {
      NProgress.start();
      progressBarStarted = true;
    }
    activeRequests++;
  }

  // Stop progress bar when all requests are done
  function stopProgressBar() {
    activeRequests--;
    if (activeRequests === 0) {
      NProgress.done();
      progressBarStarted = false;
    }
  }

  // Request Interceptor
  axiosInstance.interceptors.request.use(
    request => {
      startProgressBar();
      return request;
    },
    error => {
      stopProgressBar();
      return Promise.reject(error);
    }
  );

  // Response Interceptor
  axiosInstance.interceptors.response.use(
    response => {
      stopProgressBar();
      return response;
    },
    async error => {
      stopProgressBar();
      console.log(error);

      // Handle specific errors and gracefully handle them
      if (
        error.request.responseType === 'blob' &&
        error.response.data.type.toLowerCase().includes('json')
      ) {
        return new Promise(async (resolve, reject) => {
          const bufferArray = await error.response.data.text();
          const err = JSON.parse(bufferArray);
          reject({ open: true, type: 'error', message: err.error });
        });
      }
      if (error.message === 'Network Error') {
        return new Promise((resolve, reject) => {
          reject({ open: true, type: 'error', status: error?.response?.status });
        });
      }

      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject({
            open: true,
            type: 'error',
            message: error.response.data.error,
            status: error?.response?.status,
          });
        });
      }

      if (error.response.status === 401) {
        clearTokenAndRedirectToHome();
        return Promise.reject({
          open: true,
          type: 'error',
          message: error.response.data.message || 'Unauthorized access.',
          status: error.response.status,
        });
      }

      return Promise.reject({
        open: true,
        type: 'error',
        message:
          error.response.data.error || error.response.data.message || 'Something went wrong.',
      });
    }
  );

  return axiosInstance;
}
