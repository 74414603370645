import { useQuery } from '@tanstack/react-query';
import { checkGlobalPermission } from '../../services/checkGlobalPermission';

const useFetchPermission = (permissionTag: string, assetId?: string) => {
    const { data, isPending, error, isError, refetch } = useQuery({
        queryKey: ['permissions', permissionTag, assetId],
        queryFn: () => checkGlobalPermission({ permissionTag, assetId }),
        enabled: !!permissionTag,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
    });

    return {
        isLoading: isPending,
        error,
        isError,
        isAuthorized: data,
        refetch
    };
};

export default useFetchPermission;