import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactElement } from 'react';

interface ILoader {
  color?: string,
  height?: string,
  width?: string,
  children?: ReactElement
}
const Loader = ({ color='#36A2C7', height="80vh", width='100%', children }: ILoader) => {
  return (
    <Box width={width} display="flex" justifyContent="center" alignItems="center" height={height}>
      <CircularProgress style={{ color }} />
      {children}
    </Box>
  );
};

export default Loader;