import type { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Loader from '../../../../globalComponents/loader/DPLoader';
import useFetchPermission from '../../../../utilities/customHooks/useFetchPermission';
import ErrorScreen from '../ErrorScreen';

interface ProtectedRouteProps {
    permission: string;
    element: ReactNode;
    fallbackPath?: string;
    loadingComponent?: ReactNode;
    unauthorizedComponent?: ReactNode;
}

const ProtectedRoute = ({
    permission,
    element,
    fallbackPath = '/authorizationFailed',
    loadingComponent = <Loader />,
    unauthorizedComponent,
}: ProtectedRouteProps) => {
    const location = useLocation();
    const { isAuthorized, isLoading, isError, error, refetch } = useFetchPermission(permission);

    if (isLoading) {
        return <>{loadingComponent}</>;
    }

    if (isError) {
        return <ErrorScreen message={error?.message} onRetry={refetch}/>;
    }

    if (isAuthorized) {
        return <>{element}</>;
    }

    if (unauthorizedComponent) {
        return <>{unauthorizedComponent}</>;
    }

    return <Navigate to={fallbackPath} state={{ from: location }} replace />;
};

export default ProtectedRoute;
