import React, { createContext, useContext, useState } from 'react';

interface LoadingContextType {
  loadingCount: number;
  incrementLoadingCount: () => void;
  decrementLoadingCount: () => void;
}
const LoadingContext = createContext<LoadingContextType | undefined>(undefined);

const LoadingProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const [loadingCount, setLoadingCount] = useState(0);

  const incrementLoadingCount = () => {
    setLoadingCount(prev => prev + 1);
  };

  const decrementLoadingCount = () => {
    setLoadingCount(prev => prev - 1);
  };

  return (
    <LoadingContext.Provider
      value={{
        loadingCount,
        incrementLoadingCount,
        decrementLoadingCount,
      }}
    >
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoading = (): LoadingContextType => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error('useLoading must be used within a LoadingProvider');
  }
  return context;
};

export { LoadingProvider, LoadingContext };
