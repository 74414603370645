import React, { Suspense, ComponentType } from 'react';
import './style.css';
import { CircularProgress } from '@mui/material';

const LoadingFallback = (<div className='route-loading'>
  {/* <object data={Ab} /> */}
  <CircularProgress />
</div>);

function withLazyLoading<P>(importFunc: () => Promise<{ default: ComponentType<P> }>, fallback: React.ReactNode = LoadingFallback) {
  const LazyComponent = React.lazy(importFunc);

  return (props: any) => (
    <Suspense fallback={fallback}>
      <LazyComponent {...props} />
    </Suspense>
  );
}

export default withLazyLoading;